import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'
import { ClientJS } from 'clientjs'
import Device from '@skillnull/device-js'
import { getPoints, presentPointsAgain, getSignInDetail, getRefundPoints, getOrderCountWithStatus } from 'base/api'
import { dateParse, parseTime } from 'base/utils/ruoyi'
import { sendCacheQtEvents } from 'base/utils/quickTracking'
const user = {
  state: {
    userId: '',
    userIcon: null,
    nickName: null,
    unionId: null,
    openid: null,
    isLogin: false,
    isSignIn: false,
    isFollowWxPub: false,
    isReceiveMsg: true,
    points: {
      activePoints: null
    },
    signDetail: {
      continuousDays: 0,
      signInDate: new Date()
    },
    signDetails: [],
    orderCount: 0, //用户订单总数
    unpayOrderCount: 0, //用户未付款订单总数
    undeliverOrderCount: 0, //用户待发货订单总数
    unreceiveOrderCount: 0, //用户待收货订单总数
    aftersaleOrderCount: 0, //用户售后订单总数
    refundPoint: 0, //用户在全额返活动中获取的总数
    phone: null,
    phoneCode: '',
    smartPhone: '',
    authVersion: '2.1.0', //控制本地缓存的授权信息版本
    searchHistoryList: [], // 储存搜索页搜索历史
    isNewUser: true, // 是否为新用户
    presentPoint: 0, // 赠送point弹窗中显示的point
    showPresentPoint: false, // 是否显示赠送point弹窗
    webFingerPrint: null, // 用户浏览器指纹
    alipayUserId: null,
    alipayAddressAuth: false, // 支付宝地址授权状态
    isFirstOrderUser: null, // 是否是首单用户
    virtualCouponValue: 200, //虚拟优惠券面额
    isPacketUsed: false,
    clickRedPacketStep: 'first',
    routerInterceptFirst: true
  },

  mutations: {
    SETKEY_USER: (state, { key, value }) => {
      state[key] = value
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_ISLOGIN: (state, isLogin) => {
      state.isLogin = isLogin
    },
    SET_ISSIGNIN: (state, isSignIn) => {
      state.isSignIn = isSignIn
    },
    SET_POINTS: (state, points) => {
      state.points = points
    },
    SET_SIGNDETAIL: (state, signDetail) => {
      state.signDetail = signDetail
    },
    SET_SIGNDETAILS: (state, signDetails) => {
      state.signDetails = signDetails
    },
    SAVE_AUTH_INFO: (state, data) => {
      state.userId = data.userId || state.userId
      state.isLogin = true
      state.phone = data.phone || state.phone
      state.isReceiveMsg = data.isReceiveMsg || state.isReceiveMsg
      state.isFollowWxPub = data.isFollowWxPub || state.isFollowWxPub
      // state.userIcon = data.userIcon
      // state.nickName = data.nickName
      state.unionId = data.unionId || state.unionId
      state.openid = data.openid || state.openid
      // todo 替换为从后端获取
      // state.isNewUser = localStorage.getItem('userId') ? false : true
      state.presentPoint = data.jiFen || state.presentPoint
      state.showPresentPoint = false
      state.isFirstOrderUser = data.isFirstOrderUser

      if (data.alipayUserId) {
        state.alipayUserId = data.alipayUserId
        localStorage.setItem('alipayUserId', state.alipayUserId)
      }

      localStorage.setItem('phone', data.phone || state.phone)
      localStorage.setItem('authVersion', state.authVersion)
      if (state.userId) {
        sendCacheQtEvents(state.userId)
      }
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone
      localStorage.setItem('phone', state.phone)
    },
    INIT_SEARCH_HISTORY_LIST: (state) => {
      if (window.localStorage.getItem('searchHistory')) {
        state.searchHistoryList = JSON.parse(window.localStorage.getItem('searchHistory'))
      }
    },
    SAVE_SEARCH_HISTORY: (state, params) => {
      if (!state.searchHistoryList.includes(params)) {
        state.searchHistoryList.unshift(params)
        if (state.searchHistoryList.length > 10) {
          state.searchHistoryList = _.slice(state.searchHistoryList, 0, 10)
        }
      } else {
        state.searchHistoryList.splice(
          state.searchHistoryList.findIndex((item) => item === params),
          1
        )
        state.searchHistoryList.unshift(params)
      }
      localStorage.setItem('searchHistory', JSON.stringify(state.searchHistoryList))
    },
    CLEAR_SEARCH_HISTORY_LIST: (state) => {
      window.localStorage.removeItem('searchHistory')
      state.searchHistoryList = []
    }
  },

  actions: {
    // 获取用户信息
    GetPoints({ commit, state }, pId) {
      return new Promise((resolve, reject) => {
        if (!state.userId) {
          return
        }
        getPoints(state.userId, pId)
          .then((res) => {
            if (res.code === 200) {
              const data = res.data
              data.expireTime = dateParse(data.expireTime)
              commit('SET_POINTS', data)
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    GetSignInDetail({ commit, state }, dateInfo) {
      dateInfo.userId = state.userId
      return new Promise((resolve, reject) => {
        getSignInDetail(dateInfo)
          .then((response) => {
            if (response.code === 200 && response.data && response.data.length > 0) {
              commit('SET_SIGNDETAIL', response.data[0])
              commit('SET_SIGNDETAILS', response.data)
              if (
                response.data.length > 1 ||
                parseTime(new Date(), '{y}-{m}-{d}') ==
                  parseTime(dateParse(response.data[0].signInDate, '1900-01-01'), '{y}-{m}-{d}')
              ) {
                commit('SET_ISSIGNIN', true)
              }
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async getUserOrderCount({ commit, state }) {
      if (!state.userId) {
        return
      }

      try {
        const res = await getOrderCountWithStatus({ userId: state.userId })
        const { totalCount, unPayCount, unSendCount, sendCount, afterSaleCount } = res.data

        commit('SETKEY_USER', { key: 'orderCount', value: totalCount })
        commit('SETKEY_USER', { key: 'unpayOrderCount', value: unPayCount })
        commit('SETKEY_USER', { key: 'undeliverOrderCount', value: unSendCount })
        commit('SETKEY_USER', { key: 'unreceiveOrderCount', value: sendCount })
        commit('SETKEY_USER', { key: 'aftersaleOrderCount', value: afterSaleCount })
      } catch (err) {
        console.log(err)
      }
    },
    getRefundPoints({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.userId) {
          return
        }
        getRefundPoints(state.userId)
          .then((res) => {
            if (res.code === 200) {
              const data = res.data
              // console.log(data)
              commit('SETKEY_USER', { key: 'refundPoint', value: data })
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    finishPresentPoint({ commit, state }, pId) {
      return new Promise((resolve, reject) => {
        commit('SETKEY_USER', { key: 'showPresentPoint', value: false })

        if (state.isNewUser) {
          commit('SETKEY_USER', { key: 'isNewUser', value: false })
          return
        }

        if (!state.userId) {
          return
        }

        // todo 请求后端奖励point

        getPoints(state.userId, pId)
          .then((res) => {
            if (res.code === 200) {
              const data = res.data
              data.expireTime = dateParse(data.expireTime)
              commit('SET_POINTS', data)
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    finishPresentPointAgain({ dispatch, state }, payload) {
      const { pId, skuId, activityType, activityGoodsSpecId } = payload
      return new Promise((resolve, reject) => {
        if (!state.userId) {
          return
        }
        const goodsSpecId = skuId ? Number(skuId) : null
        presentPointsAgain({ pId: Number(pId), userId: state.userId, goodsSpecId, activityType, activityGoodsSpecId })
          .then((res) => {
            if (res.code === 200) {
              resolve(res)
              dispatch('GetPoints', pId)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getWebFingerPrint({ commit, state }, payload) {
      // eslint-disable-next-line no-async-promise-executor,no-unused-vars
      return new Promise(async (resolve, reject) => {
        let print1 = null,
          print2 = null,
          print3 = null
        // getCurrentBrowserFingerPrint().then((fingerprint) => {
        //   console.log('fingerprint: ', fingerprint)
        //   print1 = fingerprint
        //   return resolve()
        // })
        const resp1 = await getCurrentBrowserFingerPrint()
        print1 = resp1
        const client = new ClientJS()
        const fingerprint = client.getFingerprint()
        print2 = fingerprint

        const resp2 = await Device.Info({ info: ['fingerprint'] })
        print3 = resp2.fingerprint
        commit('SETKEY_USER', { key: 'webFingerPrint', value: `${print1},${print2},${print3}` })
        localStorage.setItem('webFingerPrint', `${print1},${print2},${print3}`)
      })
    }
  }
}

export default user
