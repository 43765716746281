/* eslint-disable camelcase */
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import router from './router'
import './layout/rem'
import Cookies from 'js-cookie'
import 'basscss/css/basscss.min.css'
import 'animate.css'
import 'base/assets/styles/css/customAnimate.css'
import 'base/assets/styles/css/common.less'
import 'base/assets/styles/css/color.css'
import 'base/assets/font/iconfont.css'
import 'tailwindcss/tailwind.css'
// import '@/base/assets/styles/tailwindPresets/components/index.scss'
// import { getBuryPointConfig } from 'base/api'
// 全局引入UI库 vant
// import 'base/plugins/vant'
import utils from 'base/utils'
import brand from 'base/brands'
import {
  List,
  Sticky,
  Dialog,
  Swipe,
  SwipeItem,
  CountDown,
  Image,
  Switch,
  Icon,
  Loading,
  Button,
  Tag,
  Popup,
  Form,
  Field,
  Area,
  Overlay,
  RadioGroup,
  Radio,
  Checkbox,
  Divider,
  Rate,
  Cell,
  CellGroup,
  Uploader
} from 'vant'

import Exposure from 'base/utils/exposure'
import VueLazyload from 'vue-lazyload'
import { updatePidWhenRouteChange } from './utils/userState'
// import { listenUnloadEvents } from 'base/utils/rp'
import { loadQuickTrackSDK, sendPageTrackEvent } from 'base/utils/quickTracking'

Vue.use(require('vue-wechat-title'))
Vue.use(List)
Vue.use(Sticky)
Vue.use(Dialog)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(CountDown)
Vue.use(Image)
Vue.use(Switch)
Vue.use(Icon)
Vue.use(Loading)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Area)
Vue.use(Overlay)
Vue.use(RadioGroup).use(Radio)
Vue.use(Checkbox)
Vue.use(Divider)
Vue.use(Rate)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Uploader)
Vue.config.productionTip = false

Vue.use(VueLazyload, {
  preload: 1.3, //预加载的宽高
  attempt: 3, //尝试加载的次数
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove'] //你想让vue监听的事件
})

Vue.prototype.basicImagePath = 'base/assets/'
Vue.prototype.cookie = Cookies
Vue.prototype.renderBrandElement = brand.renderBrandElement
Vue.prototype.globalPointName = brand.renderBrandElement({ id: 'global.pointName' })
Vue.prototype.globalExchangeWord = brand.renderBrandElement({ id: 'global.exchangeWord' })
Vue.prototype.GlobalLogo =
  brand.renderBrandElement({ id: 'global.logo', type: 'image' }) || require(process.env.VUE_APP_GLOBAL_LOGO)

loadQuickTrackSDK()

/**
 * 覆写vue-router的push方法，默认在query中添加pId参数
 * 用于在路由中显示的添加pId参数，以避免用户复制链接时丢掉pId的问题。
 * 如果后续不需要处理此场景，则可以去掉此方法
 */
const protoRouterPush = VueRouter.prototype.push
if (protoRouterPush) {
  VueRouter.prototype.push = function push(location, ...args) {
    return protoRouterPush.call(
      this,
      {
        ...location,
        query: {
          ...location.query,
          pId: store.state.appState.pId
        }
      },
      ...args
    )
  }
}

if (process.env.NODE_ENV !== 'production' || utils.getUrlParam('debug') === '1') {
  // 微信的vConsole
  // https://github.com/Tencent/vConsole/blob/dev/doc/tutorial_CN.md
  const VConsole = require('vconsole')
  // eslint-disable-next-line no-unused-vars
  new VConsole()
}

let startTime = 0
router.beforeEach((to, from, next) => {
  sessionStorage.setItem('prevPage', from?.path || '')
  if (from.meta.keepAlive) {
    from.meta.savedPosition = document.documentElement.scrollTop
  }
  // console.log(getPagePerformance())
  store.dispatch('getWebFingerPrint')

  let forwardPathStayTime = null
  const forwardPath = from?.path || '' // 前向来源页面
  if (startTime) {
    forwardPathStayTime = (Date.now() - startTime) / 1000
  }
  startTime = Date.now()
  const { aplus_queue } = window
  if (aplus_queue) {
    aplus_queue.push({
      action: 'aplus.appendMetaInfo', //追加全局属性
      arguments: [
        'globalproperty',
        {
          forward_path: forwardPath,
          forward_path_stay_time: forwardPathStayTime
        }
      ]
    })
    const path = to.path
    updatePidWhenRouteChange(path)
    sendPageTrackEvent(path)
  }
  // const eventPagePath = to.name === 'goodsProfile' ? '/goodsProfile' : `${to.path}`

  // #app的 dom 变为可滚动，则每次路由跳转时，滚回顶部。
  const domApp = document.getElementById('app')
  domApp.scrollTop = 0

  next()
})
// getBuryPointConfig().then((rpRes) => {
//   const {
//     exposeDuration = '300',
//     exposeMinAreaRate = 0.8,
//     pushInterval = '2000',
//     queueSize = 20,
//     eventTypes = []
//   } = rpRes?.data ?? {}

//   window.localStorage.setItem(
//     'buryPointConfig',
//     JSON.stringify({
//       exposeDuration: Number(exposeDuration),
//       exposeMinAreaRate: Number(exposeMinAreaRate),
//       pushInterval: Number(pushInterval),
//       queueSize,
//       eventTypes
//     })
//   )
//   const exp = new Exposure()
//   Vue.directive('track', {
//     // 调用指令声明周期钩子函数bind，其他钩子函数请移步官网
//     bind(el, binding) {
//       // 获取指令参数
//       const { arg } = binding
//       arg.split('|').forEach((item) => {
//         // 曝光
//         if (item === 'exposure') {
//           exp.add({ el })
//         }
//       })
//     }
//   })
//   // 监听浏览器关闭事件，上传埋点缓存队列数据
//   listenUnloadEvents(exp.trackFromLocalStorage())
// })

const exp = new Exposure()
Vue.directive('track', {
  // 调用指令声明周期钩子函数bind，其他钩子函数请移步官网
  bind(el, binding) {
    // 获取指令参数
    const { arg } = binding
    arg.split('|').forEach((item) => {
      // 曝光
      if (item === 'exposure') {
        exp.add({ el })
      }
    })
  }
})

new Vue({
  router,
  render: (h) => h(App),
  store
}).$mount('#app')
